<template>
  <v-footer dark class="mt-10 footerbg bottom absolute fixed">
    <v-row class="d-flex justify-center bigpaddingtop pt-10 pb-0 mb-0">
      <v-col lg="2" class="d-flex justify-center">
        <v-card
          class="d-flex flex-column align-center black--text"
          elevation="0"
          color="transparent"
        >
          <v-img
            alt="Onyshare Logo"
            class="shrink"
            contain
            src="../../public/logo.png"
            transition="scale-transition"
            width="70"
          />
          <v-card-text class="" :style="{ color: $vuetify.theme.currentTheme.footerT }">
            ©{{ new Date().getFullYear() }} —
            <strong> Onyshare. All Rights Reserved.</strong>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="1" class="d-flex justify-center">
        <v-card class="d-flex flex-column" elevation="0" color="transparent">
          <p :style="{ color: $vuetify.theme.currentTheme.footerT }" class="footertitle">DOWNLOAD</p>
          <p class="footer"><a @click="$router.push('/download')">Windows</a></p>
          <p class="footer"><a @click="$router.push('/download')">Mac</a></p>
          <p class="footer"><a @click="$router.push('/download')">Linux</a></p>
        </v-card>
      </v-col>
      <v-col lg="1" class="d-flex justify-center">
        <v-card class="d-flex flex-column" elevation="0" color="transparent">
          <p :style="{ color: $vuetify.theme.currentTheme.footerT }" class="footertitle">FEATURES</p>
          <p class="footer"><a>Photo Capture</a></p>
          <p class="footer"><a>Video Capture</a></p>
          <p class="footer"><a>File Sharing</a></p>
          <p class="footer"><a>Configurable Settings</a></p>
          <p class="footer"><a>Rebindable Shortcuts</a></p>
          <p class="footer"><a>Instant Capture</a></p>
        </v-card>
      </v-col>
      <v-col lg="1" class="d-flex justify-center">
        <v-card class="d-flex flex-column" elevation="0" color="transparent">
          <p :style="{ color: $vuetify.theme.currentTheme.footerT }" class="footertitle">LEARN MORE</p>
          <p class="footer"><a>Pricing</a></p>
          <p class="footer"><a>Onyshare Pro</a></p>
        </v-card>
      </v-col>
      <v-col lg="1" class="d-flex justify-center">
        <v-card class="d-flex flex-column" elevation="0" color="transparent">
          <p :style="{ color: $vuetify.theme.currentTheme.footerT }" class="footertitle">SUPPORT</p>
          <p  class="footer"><a @click="$router.push('/terms')">Terms of Service</a></p>
          <p class="footer"><a @click="$router.push('/privacy')">Privacy Policy</a></p>
          <p class="footer"><a>Contact Us</a></p>
          <p class="footer"><a>Discord</a></p>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style scoped>
.footer {
  font-size: 13px;
}
.footertitle {
  font-weight: bold;
  font-size: 15px;
}
</style>
<script>
export default {
  name: "Footer",

  data: function () {
    return {};
  },
};
</script>
