import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                bg: '#ffffff',
                footerbg: '#f5f5f5',
                footerT: '#000000',
                cookies: '#E5E5E5'
            },
            dark: {
                primary: '#1766b4',
                secondary: '#FFC107',
                accent: '#FF4081',
                error: '#FF5252',
                info: '#1766b4',
                success: '#4CAF50',
                warning: '#FFC107',
                bg: '#2b2b2b',
                footerbg: '#202020',
                footerT: '#FFFFFF',
                cookies: '#2b2b2b'
            }
        }
    }
});

