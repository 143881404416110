<template>
<div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
        <p class="homepagetitle">
          The fastest and easiest way to capture screenshots
        </p>
        <p class="homepagedesc">
          Instantly capture screenshots, gifs and videos via shareable links, all within one app.<br /><br />
          Get ready to start sharing.
        </p>
        <v-btn width="65%" height="50px" color="primary" to='/download'>Download for free</v-btn>
      </v-card>
      </v-col>
      <v-col lg="3" sm="6" class="d-flex justify-center">
        <!-- <v-img src="https://via.placeholder.com/450x270"></v-img> -->
        <v-img src="../assets/images/undraw_portfolio_re_qwm5.svg"></v-img>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/landscape.png" contain width="164"></v-img>
          <p class="homepagecard">Screenshots</p>
          <p class="text-center">Screen capture any application, the fastest and easiest way.</p>
        </v-card>
      </v-col>
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/screenshot.png" contain width="164"></v-img>
          <p class="homepagecard">Videos</p>
          <p class="text-center">Record gameplay, create tutorials and more with video captures.</p>
        </v-card>
      </v-col>
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/image.png" contain width="164"></v-img>
          <p class="homepagecard">Region or Full Screen</p>
          <p class="text-center">Capture your screenshot and videos the way you want.</p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img src="https://via.placeholder.com/450x270"></v-img> -->
          <v-img src="../assets/images/undraw_share_link_re_54rx.svg"></v-img>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            It's as easy as 1-2-3
          </p>
          <p class="homepagedesc">
            1. Press the capture shortcut.<br />
            2. Capture the area.<br />
            3. Done. Share your unique url link.<br /><br />

            It's really just that simple.
          </p>
          <v-btn outlined width="50%" height="40px" color="primary" @click="$router.push('/features')">View Features</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Every moment saved
          </p>
          <p class="homepagedesc">
            After capturing we give a private link that can be shared with anyone across the world.<br /><br />
            Organise your captures with collections that can be shared with friends, coworkers or even strangers!
          </p>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img src="https://via.placeholder.com/450x270"></v-img> -->
          <v-img src="../assets/images/undraw_upload_re_pasx.svg"></v-img>
        </v-card>
      </v-col>
    </v-row>

      <v-row class="d-flex justify-center bigpaddingtop">
        <v-col lg="3" sm="12" class="d-flex justify-center">
          <v-card class="d-flex flex-column align-center" elevation="0" color="transparent">
            <p class="homepagetitle text-center">Choose the best plan for you</p>
            <p class="text-center">We got you covered, whatever plan you choose.</p>
            <br />
            <p class="text-center">Enjoy free and unlimited updates and support on all packages.</p>
            <br />
          </v-card>
        </v-col>
        <v-col lg="2" class="d-flex justify-center">
          <v-card class="d-flex flex-column justify-space-between align-center pa-3 footerbg" min-height="320" width="300" elevation="5" color="transparent">
            <p class="tiercardtitle">Onyshare Basic</p>
            <p class="pt-5 pb-1 text-center">Try Onyshare basic for an unlimited time, completely free.</p>
            <div class="pb-5"><span class="pricetitle">$0</span><span class="pricemonth pt-5">/month</span></div>
            <v-btn outlined height="40px" width="90%" color="primary">Get Started</v-btn>
          </v-card>
        </v-col>
        <v-col lg="2" class="d-flex justify-center">
          <v-card class="d-flex flex-column justify-space-between align-center pa-3 footerbg" min-height="320" width="300" elevation="5" color="transparent">
            <p class="tiercardtitle">Onyshare Pro</p>
            <p class="pt-5 pb-1 text-center">Onyshare without limitations. Everything you need.</p>
            <div class="pb-5"><span class="pricetitle">$10</span><span class="pricemonth pt-5">/month</span></div>
            <v-btn outlined height="40px" width="90%" color="primary">Get Onyshare Pro</v-btn>
          </v-card>
        </v-col>
      </v-row>


    <v-row class="justify-center bigpaddingtop">
      <v-col class="d-flex align-center flex-column" lg="4">
       <!-- <v-img class="text-center" width="100%" src="https://via.placeholder.com/600x350"></v-img> -->
       <v-img src="../assets/images/undraw_my_files_swob.svg"></v-img>
       <p class="homepagetitle text-center my-7">Start sharing with Onyshare</p>
       <v-btn outlined width="50%" class="text-center" height="40px" color="primary" to='/download'>Download for free</v-btn>
      </v-col>
    </v-row>
    </div>
</template>

<style scoped>

.homepagetitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
}

.homepagedesc {
  font-size: 17px;
}

.homepagecard {
  font-size: 17px;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 10rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: 'Home',
};
</script>
