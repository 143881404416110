<template>
  <v-app>
    <v-bottom-sheet v-model="sheet" hide-overlay persistent>
      <v-sheet class="text-center" height="80px" color="cookies">
        <v-container fill-height>
          <v-row class="justify-center" align="center" style="font-size: 14px">
            <span>
              This website uses cookies to ensure you get the best experience on
              our website
            </span>
            <v-btn class="ml-2" outlined color="primary" @click="cookie()" width="100px">
              Got it!
            </v-btn>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
    <v-alert dense type="info" v-if="!$route.meta.hideFooter">
      We're still in <strong>development</strong> but you can register with us and <a style="color: wheat;"  @click="$router.push('/download')">use the app</a>.
    </v-alert>
    <v-container fluid>
      <Navbar v-if="!$route.meta.hideNavbar"></Navbar>
      <router-view />
      <SniprshotDialog></SniprshotDialog>
    </v-container>
    <Footer v-if="!$route.meta.hideFooter"></Footer>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import SniprshotDialog from "./components/SniprshotDialog.vue";
import config from "./config";

export default {
  name: "App",
  data: function () {
    return {
      sheet: true,
    };
  },
  async created() {

    if (localStorage.getItem("Onyshare-GDPR")) {
      this.sheet = false;
    } else {
      this.sheet = true;
    }

    const token = localStorage.getItem("access_token");
    if (token) {
      try {
        const response = await fetch(`${config.URL}/verifytoken`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "x-access-token": token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();

          this.$store.commit("connectUser", {
            id: data.id,
            email: data.email,
            name: data.name,
            accessToken: token,
            premium: data.premium,
            size: data.size,
            twofactor: data.twofactor,
            captureSize: data.captureSize,
            userLevel: data.userLevel,
            userPermissions: data.userPermissions,
            collections: data.collections,
          });
        } else {
          // handle error response status
          this.$store.commit("disconnectUser");
          //this.$router.push("/");
        }
      } catch (error) {
        // display error message to the user
        this.$store.commit("disconnectUser");
        //console.log(error);
        //this.$router.push("/");
      }
    }
  },
  methods: {
    showGDPR(v) {
      this.sheet = v;
    },
    cookie() {
      localStorage.setItem("Onyshare-GDPR", false);
      this.sheet = false;
    },
  },
  components: {
    Navbar,
    Footer,
    SniprshotDialog,
  },
  computed: {
    showDialog: function () {
      return this.$store.globalDialog.state.showGlobalDialog;
    },
  },
};
</script>
